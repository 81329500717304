export default function HelpDraw() {
	return (
		// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1228.9362850971925 541.7818574514039"
			width="1228.9362850971925"
			height="541.7818574514039"
		>
			<rect
				x="0"
				y="0"
				width="1228.9362850971925"
				height="541.7818574514039"
				fill="#ffffff"
			/>
			<g
				stroke-linecap="round"
				transform="translate(363.133909287257 10) rotate(0 76.32721382289418 25.126349892008648)"
			>
				<path
					d="M12.56 0 M12.56 0 C58 0.97, 102.14 -0.12, 140.09 0 M12.56 0 C49.59 1.62, 86.49 0.12, 140.09 0 M140.09 0 C146.54 -1.1, 154.56 2.57, 152.65 12.56 M140.09 0 C149.48 -2.25, 152.14 3.74, 152.65 12.56 M152.65 12.56 C153.87 17.6, 152.06 26.07, 152.65 37.69 M152.65 12.56 C152.64 19.1, 152.25 24.6, 152.65 37.69 M152.65 37.69 C154.61 45.28, 149.38 49.98, 140.09 50.25 M152.65 37.69 C151.81 46.76, 146.83 51.17, 140.09 50.25 M140.09 50.25 C95.08 51.32, 52.01 50.7, 12.56 50.25 M140.09 50.25 C109.94 51.31, 79.89 51.54, 12.56 50.25 M12.56 50.25 C4.73 51.71, -1.27 45.43, 0 37.69 M12.56 50.25 C4.46 48.99, -2.14 48.08, 0 37.69 M0 37.69 C-0.85 29.06, 0.65 20.4, 0 12.56 M0 37.69 C0.15 32.78, -0.72 26.15, 0 12.56 M0 12.56 C1.44 4.69, 5.04 -1.45, 12.56 0 M0 12.56 C-1.52 5.4, 1.9 2.23, 12.56 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				/>
			</g>
			<g transform="translate(382.7911249412059 24.326349892008636) rotate(0 56.66999816894531 10.799999999999997)">
				<text
					x="56.66999816894531"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="18.894626178250096px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					判断房间状态
				</text>
			</g>
			<g
				stroke-linecap="round"
				transform="translate(351.51187904967605 140.74298056155504) rotate(0 81.06803455723542 25.600431965442766)"
			>
				<path
					d="M12.8 0 M12.8 0 C44.17 -0.2, 79.5 0.88, 149.34 0 M12.8 0 C48.94 0.21, 83.52 -0.36, 149.34 0 M149.34 0 C156.85 -0.61, 161.34 2.69, 162.14 12.8 M149.34 0 C158.2 -1.46, 160.76 2.36, 162.14 12.8 M162.14 12.8 C162.68 20.11, 161.37 26.24, 162.14 38.4 M162.14 12.8 C162.02 18.85, 163.1 24.1, 162.14 38.4 M162.14 38.4 C161.27 47.46, 156.92 51.22, 149.34 51.2 M162.14 38.4 C164.01 47.16, 159.2 50.18, 149.34 51.2 M149.34 51.2 C110.42 52.29, 71.32 51.09, 12.8 51.2 M149.34 51.2 C96.34 49.4, 45.44 49.53, 12.8 51.2 M12.8 51.2 C5.84 50.89, -1.24 46.41, 0 38.4 M12.8 51.2 C3.1 51.22, -0.28 49.11, 0 38.4 M0 38.4 C-0.24 32.94, -0.06 27.58, 0 12.8 M0 38.4 C0.08 31.65, 0.78 26.15, 0 12.8 M0 12.8 C0.93 4.65, 4.09 1.17, 12.8 0 M0 12.8 C1.38 3.08, 3.47 -0.73, 12.8 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(375.9099154379662 155.54341252699783) rotate(0 56.66999816894531 10.800000000000011)">
				<text
					x="56.66999816894531"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="18.89889057322618px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					房间是否空置
				</text>
			</g>
			<g
				stroke-linecap="round"
				transform="translate(589.5010799136069 141.69114470842334) rotate(0 74.9049676025918 25.600431965442766)"
			>
				<path
					d="M12.8 0 M12.8 0 C59.01 -0.04, 101.84 0.6, 137.01 0 M12.8 0 C47.5 0.22, 83.08 0.71, 137.01 0 M137.01 0 C147.17 1.57, 151.19 4.42, 149.81 12.8 M137.01 0 C144.07 -0.59, 150.03 3.45, 149.81 12.8 M149.81 12.8 C151.16 24.02, 151.27 31.58, 149.81 38.4 M149.81 12.8 C149 21.74, 149.54 30.29, 149.81 38.4 M149.81 38.4 C150.54 47.94, 145.97 52.01, 137.01 51.2 M149.81 38.4 C149.75 46.4, 144.25 49.08, 137.01 51.2 M137.01 51.2 C90.87 49.31, 46.91 50.05, 12.8 51.2 M137.01 51.2 C104.1 52.01, 71.06 51.42, 12.8 51.2 M12.8 51.2 C5.75 49.62, -0.78 48.75, 0 38.4 M12.8 51.2 C2.79 50.5, -1.1 45.61, 0 38.4 M0 38.4 C1.37 31.25, -0.83 25.45, 0 12.8 M0 38.4 C0.27 31.19, 0.13 24.25, 0 12.8 M0 12.8 C1.48 2.97, 5.52 -1.57, 12.8 0 M0 12.8 C-0.14 5.61, 2.48 -1.32, 12.8 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				/>
			</g>
			<g transform="translate(617.1810490420776 156.49157667386612) rotate(0 47.224998474121094 10.800000000000011)">
				<text
					x="47.224998474121094"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="18.893234487186984px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					空置则入住
				</text>
			</g>
			<g
				stroke-linecap="round"
				transform="translate(823.6976241900647 147.38012958963282) rotate(0 135.58747300215987 22.755939524838027)"
			>
				<path
					d="M11.38 0 M11.38 0 C93.74 0.76, 179.46 2.57, 259.8 0 M11.38 0 C96.37 1.24, 182.3 0.88, 259.8 0 M259.8 0 C266.05 -0.63, 272.28 2.64, 271.17 11.38 M259.8 0 C269.39 0.71, 269.37 3.63, 271.17 11.38 M271.17 11.38 C272.04 18.42, 270.31 25.8, 271.17 34.13 M271.17 11.38 C271.94 17.25, 270.94 23, 271.17 34.13 M271.17 34.13 C269.33 40.22, 268.52 47.16, 259.8 45.51 M271.17 34.13 C269.25 43.74, 267.5 45.2, 259.8 45.51 M259.8 45.51 C187.62 47.24, 112.7 45.83, 11.38 45.51 M259.8 45.51 C163.1 47.14, 66.6 45.81, 11.38 45.51 M11.38 45.51 C4.3 45.25, -1.94 43.48, 0 34.13 M11.38 45.51 C3.8 47.79, -0.31 42.35, 0 34.13 M0 34.13 C-0.37 26.68, -0.33 19.71, 0 11.38 M0 34.13 C0.63 27.54, 0.66 21.47, 0 11.38 M0 11.38 C-0.03 3.39, 3.3 -1.45, 11.38 0 M0 11.38 C-0.82 3.81, 1.77 1.03, 11.38 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(845.7651081785527 159.33606911447083) rotate(0 113.51998901367188 10.800000000000011)">
				<text
					x="113.51998901367188"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="18.925720724950704px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					收取押金、租金、记录水电
				</text>
			</g>
			<g
				stroke-linecap="round"
				transform="translate(320.8725701943845 248.79697624190067) rotate(0 73.95680345572356 24.178185745140382)"
			>
				<path
					d="M12.09 0 M12.09 0 C55.19 -1.15, 94.41 0.44, 135.82 0 M12.09 0 C48.38 1.39, 84.56 0.79, 135.82 0 M135.82 0 C142.4 -0.97, 148.71 2.29, 147.91 12.09 M135.82 0 C144.54 -1.88, 148.11 5.84, 147.91 12.09 M147.91 12.09 C146.59 17.79, 149.89 21.74, 147.91 36.27 M147.91 12.09 C147.85 17.35, 148.05 22.9, 147.91 36.27 M147.91 36.27 C148.93 44.52, 142.78 49.69, 135.82 48.36 M147.91 36.27 C146.12 44.75, 145.6 46.33, 135.82 48.36 M135.82 48.36 C88 50.29, 37.48 48.22, 12.09 48.36 M135.82 48.36 C104.73 48.51, 71.95 48.67, 12.09 48.36 M12.09 48.36 C3.64 46.37, 1.83 45.38, 0 36.27 M12.09 48.36 C5.26 49, 1.34 46.32, 0 36.27 M0 36.27 C-0.83 28.58, -0.06 18.05, 0 12.09 M0 36.27 C0.68 30.31, 0.23 25.16, 0 12.09 M0 12.09 C1.44 2.61, 5.18 -0.65, 12.09 0 M0 12.09 C-0.14 5.48, 6.33 -0.89, 12.09 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(366.4943745656353 262.17516198704107) rotate(0 28.334999084472656 10.800000000000011)">
				<text
					x="28.334999084472656"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="18.89227491937633px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					非空置
				</text>
			</g>
			<g
				stroke-linecap="round"
				transform="translate(73.37365010799135 384.7818574514039) rotate(0 90.07559395248381 23.704103671706264)"
			>
				<path
					d="M11.85 0 M11.85 0 C45.3 -1.7, 77.44 0.13, 168.3 0 M11.85 0 C56.48 -0.01, 102.05 1.31, 168.3 0 M168.3 0 C176.22 0.1, 181.4 4.91, 180.15 11.85 M168.3 0 C176.94 1.86, 178.83 2.78, 180.15 11.85 M180.15 11.85 C181.84 19.75, 180.23 22, 180.15 35.56 M180.15 11.85 C179.6 18.38, 179.95 26.26, 180.15 35.56 M180.15 35.56 C181.89 42.49, 175.41 47.1, 168.3 47.41 M180.15 35.56 C178.38 44.29, 178.08 47.13, 168.3 47.41 M168.3 47.41 C113.32 46.33, 60.34 49.22, 11.85 47.41 M168.3 47.41 C116.06 47.53, 62.37 46.98, 11.85 47.41 M11.85 47.41 C5.63 48.74, -0.05 42.42, 0 35.56 M11.85 47.41 C4.85 48.68, -1.92 43.05, 0 35.56 M0 35.56 C0.91 27.33, -0.65 22.43, 0 11.85 M0 35.56 C-0.94 27.8, -0.76 19.74, 0 11.85 M0 11.85 C-1.07 4.1, 4.9 1.78, 11.85 0 M0 11.85 C-1.18 4.12, 5.9 -1.3, 11.85 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(116.19925931926423 397.68596112311013) rotate(0 47.24998474121094 10.800000000000011)">
				<text
					x="47.24998474121094"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="18.905687544996397px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					续租则月结
				</text>
			</g>
			<g
				stroke-linecap="round"
				transform="translate(10 490.7818574514039) rotate(0 119.5 20.5)"
			>
				<path
					d="M10.25 0 M10.25 0 C63.62 -2.61, 118.29 -1.86, 228.75 0 M10.25 0 C94.67 0.46, 180.05 -0.14, 228.75 0 M228.75 0 C237.58 -0.11, 238.75 1.42, 239 10.25 M228.75 0 C234.86 0.69, 240.11 4.12, 239 10.25 M239 10.25 C236.95 15.55, 238.33 24.95, 239 30.75 M239 10.25 C238.93 17.09, 238.64 25.37, 239 30.75 M239 30.75 C238.5 39.08, 233.59 40.68, 228.75 41 M239 30.75 C238.04 37.88, 235.1 43.19, 228.75 41 M228.75 41 C174.11 38.92, 121.47 40.78, 10.25 41 M228.75 41 C146.22 39.28, 63.38 39.2, 10.25 41 M10.25 41 C3.02 41.34, 1.16 39.12, 0 30.75 M10.25 41 C3.76 42.88, 0.74 35.88, 0 30.75 M0 30.75 C0.96 22.77, 1.16 18.25, 0 10.25 M0 30.75 C0.14 26.77, -0.02 20.89, 0 10.25 M0 10.25 C-0.7 1.6, 2.99 0.21, 10.25 0 M0 10.25 C-2.27 4.23, 3.21 -1.95, 10.25 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(44.360008239746094 500.4818574514039) rotate(0 85.1399917602539 10.800000000000011)">
				<text
					x="85.1399917602539"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="18.92181425485962px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					收取租金、记录水电
				</text>
			</g>
			<g
				stroke-linecap="round"
				transform="translate(358.8250539956804 379.71706263498925) rotate(0 90.5496760259179 25.126349892008648)"
			>
				<path
					d="M12.56 0 M12.56 0 C57.47 0.76, 106.99 -1.95, 168.54 0 M12.56 0 C70.51 -0.29, 129.69 -1.84, 168.54 0 M168.54 0 C176.05 0.44, 182.34 2.73, 181.1 12.56 M168.54 0 C175.15 2.17, 182.41 5, 181.1 12.56 M181.1 12.56 C179.16 20.52, 179.19 28.35, 181.1 37.69 M181.1 12.56 C181.9 20.05, 181.32 26.59, 181.1 37.69 M181.1 37.69 C182.18 47.8, 177.57 52.12, 168.54 50.25 M181.1 37.69 C180.56 46.36, 176.37 52.4, 168.54 50.25 M168.54 50.25 C134.58 51.49, 98.55 52.86, 12.56 50.25 M168.54 50.25 C109.88 51.42, 52.78 50.75, 12.56 50.25 M12.56 50.25 C3.92 51.3, 1.54 45.26, 0 37.69 M12.56 50.25 C6.46 48.18, 1.03 45.18, 0 37.69 M0 37.69 C1.8 27.38, -0.44 17.58, 0 12.56 M0 37.69 C0.3 30.93, 0.95 25.93, 0 12.56 M0 12.56 C-1.74 4.67, 3.61 0.49, 12.56 0 M0 12.56 C1.4 1.91, 4.24 -0.03, 12.56 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(411.5747422286296 394.04341252699794) rotate(0 37.79998779296875 10.800000000000011)">
				<text
					x="37.79998779296875"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="18.906005751584065px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					租客退租
				</text>
			</g>
			<g
				stroke-linecap="round"
				transform="translate(329.7775377969763 480.76889848812095) rotate(0 123.5 24)"
			>
				<path
					d="M12 0 M12 0 C100.77 1.17, 189.13 2.72, 235 0 M12 0 C56.47 0.45, 101.54 0.4, 235 0 M235 0 C242.06 -1.86, 247.13 3.54, 247 12 M235 0 C242.76 1.73, 244.92 3.61, 247 12 M247 12 C246.9 22.18, 245.96 28.61, 247 36 M247 12 C246.93 18.84, 247.37 23.49, 247 36 M247 36 C248.28 44.93, 241.09 47.37, 235 48 M247 36 C247.31 44.59, 242.99 45.96, 235 48 M235 48 C178.25 47.26, 124.78 47.28, 12 48 M235 48 C146.86 49.82, 59.62 49.87, 12 48 M12 48 C3.13 46.94, 0.62 43.43, 0 36 M12 48 C2.78 48.99, 0.87 44.95, 0 36 M0 36 C0.96 27.46, -1.31 15.23, 0 12 M0 36 C-0.86 26.95, -0.59 19.61, 0 12 M0 12 C0.48 3.26, 4.49 -1.79, 12 0 M0 12 C1.09 5.03, 5.8 -2.12, 12 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(368.13754603672237 493.968898488121) rotate(0 85.1399917602539 10.800000000000011)">
				<text
					x="85.1399917602539"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="18.92416153877501px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					扣除水电，退还押金
				</text>
			</g>
			<g stroke-linecap="round">
				<g transform="translate(435.0043662449883 61.252699784017295) rotate(0 -4.222745102449323 37.38702145407751)">
					<path
						d="M0.26 -1.14 C-0.95 11.3, -6.52 62.18, -7.99 74.83 M-1.06 0.87 C-2.33 13.43, -7.32 63.46, -8.71 75.92"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(435.0043662449883 61.252699784017295) rotate(0 -4.222745102449323 37.38702145407751)">
					<path
						d="M-17.88 47.55 C-15.63 53.56, -13.99 61.1, -7.62 77.33 M-16.51 47.43 C-14.34 53.02, -11.9 61.1, -7.93 75.29"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(435.0043662449883 61.252699784017295) rotate(0 -4.222745102449323 37.38702145407751)">
					<path
						d="M2.53 49.69 C-0.12 55.23, -3.37 62.26, -7.62 77.33 M3.9 49.57 C1.16 54.6, -1.32 62.16, -7.93 75.29"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g stroke-linecap="round">
				<g transform="translate(514.5961123110151 167.69790161480148) rotate(0 36.9279312185609 0.244291732572691)">
					<path
						d="M0.86 -0.17 C13.23 0.12, 61.75 1.48, 74 1.8 M-0.15 -1.31 C12.09 -1.38, 60.75 -0.2, 73.08 -0.03"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(514.5961123110151 167.69790161480148) rotate(0 36.9279312185609 0.244291732572691)">
					<path
						d="M44.41 9.76 C53.56 7.47, 61.48 4.23, 71.39 0.59 M44.47 8.87 C54.12 6.54, 61.33 3, 72.26 -0.51"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(514.5961123110151 167.69790161480148) rotate(0 36.9279312185609 0.244291732572691)">
					<path
						d="M44.8 -10.76 C53.86 -7.29, 61.67 -4.77, 71.39 0.59 M44.86 -11.65 C54.26 -7.83, 61.35 -5.23, 72.26 -0.51"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g stroke-linecap="round">
				<g transform="translate(740.2591792656588 169.53647358538615) rotate(0 41.976721121541004 0.35539015359341875)">
					<path
						d="M0.73 -0.28 C14.36 -0.1, 68.47 2.02, 81.9 2.18 M-0.35 -1.47 C13.67 -1.11, 70.31 -0.05, 84.3 0.46"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(740.2591792656588 169.53647358538615) rotate(0 41.976721121541004 0.35539015359341875)">
					<path
						d="M55.39 8.99 C65.91 4.87, 72.24 2.74, 84.99 0.28 M56.27 10.37 C63.33 7.43, 70.5 4.32, 84 0.92"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(740.2591792656588 169.53647358538615) rotate(0 41.976721121541004 0.35539015359341875)">
					<path
						d="M55.93 -11.52 C66.47 -9.25, 72.63 -4.99, 84.99 0.28 M56.81 -10.14 C63.56 -7.57, 70.59 -5.19, 84 0.92"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g stroke-linecap="round">
				<g transform="translate(422.40415157714403 192.94384449244058) rotate(0 -9.577959273659701 27.336241844770626)">
					<path
						d="M0.55 1.14 C-2.47 10.03, -15.29 44.29, -18.62 52.97 M-0.61 0.69 C-3.78 9.73, -16.96 45.09, -19.71 53.99"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(422.40415157714403 192.94384449244058) rotate(0 -9.577959273659701 27.336241844770626)">
					<path
						d="M-18.29 25.04 C-21.8 31.09, -21.33 39.81, -19.65 54.13 M-20.37 26.07 C-19.55 33.68, -20.6 42.92, -19.29 53.36"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(422.40415157714403 192.94384449244058) rotate(0 -9.577959273659701 27.336241844770626)">
					<path
						d="M0.13 31.4 C-8.15 35.88, -12.48 42.94, -19.65 54.13 M-1.95 32.43 C-6.95 37.99, -13.79 45.23, -19.29 53.36"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g stroke-linecap="round">
				<g transform="translate(379.7175273907791 299.04967602591796) rotate(0 -62.99553590884773 41.736645226073534)">
					<path
						d="M0.49 -0.96 C-20.42 12.95, -104.81 69, -125.97 82.84 M-0.72 1.14 C-21.66 15.33, -105.42 70.78, -126.48 84.44"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(379.7175273907791 299.04967602591796) rotate(0 -62.99553590884773 41.736645226073534)">
					<path
						d="M-110.15 61.8 C-113.05 67.11, -118.85 74.26, -127.61 85.52 M-108.12 60.2 C-113.47 68.51, -119.23 75.13, -125.98 84.6"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(379.7175273907791 299.04967602591796) rotate(0 -62.99553590884773 41.736645226073534)">
					<path
						d="M-98.89 78.95 C-104.55 79.95, -113.22 82.74, -127.61 85.52 M-96.86 77.36 C-105.59 80.41, -114.86 81.68, -125.98 84.6"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g stroke-linecap="round">
				<g transform="translate(161.6128261186832 433.1900647948163) rotate(0 -29.004650727380636 28.340081658560848)">
					<path
						d="M1.03 -0.8 C-8.58 8.45, -48.06 46.6, -57.81 56.16 M0.11 1.4 C-9.69 10.76, -49.31 48.07, -59.04 57.48"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(161.6128261186832 433.1900647948163) rotate(0 -29.004650727380636 28.340081658560848)">
					<path
						d="M-47.03 29.69 C-50.12 38.18, -53.31 44.34, -58.82 58.47 M-45.1 31.1 C-50.81 40.58, -54.91 49.52, -58.2 57.43"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(161.6128261186832 433.1900647948163) rotate(0 -29.004650727380636 28.340081658560848)">
					<path
						d="M-32.88 44.55 C-40.13 48.88, -47.41 50.74, -58.82 58.47 M-30.95 45.96 C-41.46 50.57, -50.31 54.53, -58.2 57.43"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g stroke-linecap="round">
				<g transform="translate(400.3046735711366 300.1533477321814) rotate(0 24.739137745797564 38.472715235029796)">
					<path
						d="M0.04 0.05 C8.53 12.91, 42.53 65.12, 50.87 77.91 M-1.39 -0.96 C6.94 11.46, 40.93 62.85, 49.93 75.97"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(400.3046735711366 300.1533477321814) rotate(0 24.739137745797564 38.472715235029796)">
					<path
						d="M25.78 58.31 C33.54 62.24, 38.88 67.47, 51.82 76.59 M26.46 58.87 C34.68 65.81, 44.09 71.78, 50.22 76.38"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(400.3046735711366 300.1533477321814) rotate(0 24.739137745797564 38.472715235029796)">
					<path
						d="M42.81 46.87 C45.61 54.18, 45.92 62.79, 51.82 76.59 M43.49 47.42 C45.04 59, 47.68 69.51, 50.22 76.38"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g stroke-linecap="round">
				<g transform="translate(449.244635670801 430.96976241900654) rotate(0 -2.9014281091544945 24.605085632198097)">
					<path
						d="M-0.51 0.41 C-1.4 8.51, -5.32 41.55, -6.3 49.62 M1.42 -0.41 C0.47 7.86, -5.79 39.5, -7.22 47.93"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(449.244635670801 430.96976241900654) rotate(0 -2.9014281091544945 24.605085632198097)">
					<path
						d="M-10.63 23.74 C-8.84 28.89, -9.24 34.02, -7.11 48.34 M-12.17 23.3 C-9.93 29.87, -10.28 33.92, -6.89 47.14"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(449.244635670801 430.96976241900654) rotate(0 -2.9014281091544945 24.605085632198097)">
					<path
						d="M5.93 26.79 C4.1 31.21, 0.1 35.68, -7.11 48.34 M4.39 26.35 C3.08 32.22, -0.83 35.62, -6.89 47.14"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g
				stroke-linecap="round"
				transform="translate(641.8725701943845 379.50323974082073) rotate(0 90 25)"
			>
				<path
					d="M12.5 0 M12.5 0 C54.69 -1.01, 96.73 1.04, 167.5 0 M12.5 0 C49.83 -0.43, 86.61 -1.2, 167.5 0 M167.5 0 C175.77 -1.69, 181.85 3.18, 180 12.5 M167.5 0 C174.88 1.13, 181.74 5.78, 180 12.5 M180 12.5 C181.43 16.37, 178.93 22.75, 180 37.5 M180 12.5 C179.37 22.15, 179.84 31.35, 180 37.5 M180 37.5 C178.22 47.64, 173.89 49.19, 167.5 50 M180 37.5 C182.19 44.89, 177.42 49.06, 167.5 50 M167.5 50 C122.43 53.24, 79.04 51.71, 12.5 50 M167.5 50 C122.27 51.77, 79.32 50.93, 12.5 50 M12.5 50 C6.1 51.98, -1.42 43.97, 0 37.5 M12.5 50 C2.46 50.36, 2.12 45.44, 0 37.5 M0 37.5 C-1.33 28.5, 1.07 21.93, 0 12.5 M0 37.5 C-0.34 29.12, 0.43 20.24, 0 12.5 M0 12.5 C-1.73 3.79, 5.68 -1.99, 12.5 0 M0 12.5 C1.69 4.63, 5.64 1.37, 12.5 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(691.8725701943845 392.50323974082073) rotate(0 40 12)">
				<text
					x="40"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="20px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					租客逃离
				</text>
			</g>
			<g stroke-linecap="round">
				<g transform="translate(422.71058397725574 299.4844823927094) rotate(0 107.7117145113333 43.54578375073646)">
					<path
						d="M-0.24 -1.07 C36.06 13.55, 181.1 73.5, 217.24 88.16 M-1.82 0.99 C34.46 15.25, 180.54 72.09, 216.86 86.6"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(422.71058397725574 299.4844823927094) rotate(0 107.7117145113333 43.54578375073646)">
					<path
						d="M185.1 87.63 C196.31 86.89, 201.78 85.08, 216.49 86.08 M186.14 84.89 C193.37 85.82, 202.13 85.66, 217.22 87.42"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(422.71058397725574 299.4844823927094) rotate(0 107.7117145113333 43.54578375073646)">
					<path
						d="M192.61 68.53 C201.98 72.64, 205.54 75.69, 216.49 86.08 M193.66 65.79 C198.95 71.35, 205.9 75.79, 217.22 87.42"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g
				stroke-linecap="round"
				transform="translate(641.8725701943845 472.50323974082073) rotate(0 117.5 27.5)"
			>
				<path
					d="M13.75 0 M13.75 0 C77.54 1.17, 140.92 1.58, 221.25 0 M13.75 0 C91.25 -2.55, 168.05 -1.9, 221.25 0 M221.25 0 C231.15 1.2, 234.6 6.08, 235 13.75 M221.25 0 C229.26 -2.18, 237.02 6.3, 235 13.75 M235 13.75 C234 23.95, 236.6 30.15, 235 41.25 M235 13.75 C235.42 24.15, 234.57 32.62, 235 41.25 M235 41.25 C233.63 50.53, 230.56 53.49, 221.25 55 M235 41.25 C233.54 50.49, 228.67 54.8, 221.25 55 M221.25 55 C141.2 53.13, 60.84 52.98, 13.75 55 M221.25 55 C161.11 56.12, 100.77 56.99, 13.75 55 M13.75 55 C5.4 55.91, 1.47 49.43, 0 41.25 M13.75 55 C5.54 56.58, 1.99 48.12, 0 41.25 M0 41.25 C1.14 30.04, -1.39 19.28, 0 13.75 M0 41.25 C-0.75 30.53, 0.59 21.72, 0 13.75 M0 13.75 C-0.43 6.28, 2.8 -1.43, 13.75 0 M0 13.75 C-1.39 2.78, 4.97 0.93, 13.75 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(669.3725701943845 488.00323974082073) rotate(0 90 12)">
				<text
					x="90"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="20px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					押金不退，亏损水电
				</text>
			</g>
			<g stroke-linecap="round">
				<g transform="translate(744.9873698875856 430.50323974082073) rotate(0 -2.9980951589702727 20.019814792564148)">
					<path
						d="M-0.76 1 C-1.63 7.51, -5.24 32.22, -6.42 38.36 M1.05 0.47 C0.17 7.21, -5.98 33.21, -7.04 39.57"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(744.9873698875856 430.50323974082073) rotate(0 -2.9980951589702727 20.019814792564148)">
					<path
						d="M-8.29 20.99 C-10.52 22.65, -10.73 28.39, -8.54 40.33 M-9.48 20.94 C-8.96 27.25, -7.98 32.28, -6.65 39.14"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(744.9873698875856 430.50323974082073) rotate(0 -2.9980951589702727 20.019814792564148)">
					<path
						d="M4.99 23.73 C0.09 24.74, -2.81 29.94, -8.54 40.33 M3.8 23.67 C0.01 29.05, -3.29 33.2, -6.65 39.14"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g
				stroke-linecap="round"
				transform="translate(608.9362850971925 258.3909287257019) rotate(0 62 23)"
			>
				<path
					d="M11.5 0 M11.5 0 C31.94 -0.18, 55.48 0.58, 112.5 0 M11.5 0 C47.95 0.55, 85.21 0.68, 112.5 0 M112.5 0 C121.4 -1.72, 124.98 2.27, 124 11.5 M112.5 0 C120.55 1.05, 122.34 4.42, 124 11.5 M124 11.5 C122.22 16.68, 125.05 23.61, 124 34.5 M124 11.5 C123.32 17.04, 123.37 24.06, 124 34.5 M124 34.5 C124.86 42.01, 121.01 47.85, 112.5 46 M124 34.5 C122.99 41.22, 119.83 45.6, 112.5 46 M112.5 46 C84.26 45.36, 60.29 47.81, 11.5 46 M112.5 46 C77.12 46.09, 39.39 47.38, 11.5 46 M11.5 46 C5.33 45.35, -1.74 41.74, 0 34.5 M11.5 46 C3.36 45.75, -0.3 41.38, 0 34.5 M0 34.5 C0.32 28.27, -1.12 23.9, 0 11.5 M0 34.5 C0.8 27.15, -0.84 20.18, 0 11.5 M0 11.5 C1.43 3, 5.21 -0.17, 11.5 0 M0 11.5 C0.27 4.22, 3.83 -2.26, 11.5 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(630.9362850971925 269.3909287257019) rotate(0 40 12)">
				<text
					x="40"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="20px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					租客换房
				</text>
			</g>
			<g stroke-linecap="round">
				<g transform="translate(471.68427645788336 272.54462539217917) rotate(0 64.96192377979185 4.724023510492145)">
					<path
						d="M-0.59 0.03 C20.76 1.91, 106.53 8.74, 128.2 10.44 M1.3 -1 C23.03 0.57, 109.25 6.39, 130.51 8.49"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(471.68427645788336 272.54462539217917) rotate(0 64.96192377979185 4.724023510492145)">
					<path
						d="M101.64 15.17 C111.08 14.9, 119.64 11.53, 129.69 9.84 M101.48 15.78 C109.82 14.54, 118.66 11.25, 130.81 7.78"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(471.68427645788336 272.54462539217917) rotate(0 64.96192377979185 4.724023510492145)">
					<path
						d="M103.29 -5.29 C112.08 0.74, 120.14 3.66, 129.69 9.84 M103.13 -4.67 C110.85 0.39, 119.18 3.42, 130.81 7.78"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
			<g
				stroke-linecap="round"
				transform="translate(825.9362850971925 235.39092872570188) rotate(0 196.5 53)"
			>
				<path
					d="M26.5 0 M26.5 0 C106.29 -0.35, 186.99 0.3, 366.5 0 M26.5 0 C107.54 -0.72, 188.82 -0.56, 366.5 0 M366.5 0 C384.56 1.57, 392.32 9.52, 393 26.5 M366.5 0 C385.76 1.23, 391.44 10.28, 393 26.5 M393 26.5 C393.33 37.24, 393.05 49.94, 393 79.5 M393 26.5 C393.25 39.18, 392.88 51.81, 393 79.5 M393 79.5 C393.47 98.55, 384.68 106.29, 366.5 106 M393 79.5 C392.99 98.75, 384.96 105.06, 366.5 106 M366.5 106 C263.85 107.38, 159.76 107.4, 26.5 106 M366.5 106 C279.05 106.86, 193.3 106.54, 26.5 106 M26.5 106 C8.81 104.33, -1.78 98.53, 0 79.5 M26.5 106 C7.4 106.16, -1.59 96.86, 0 79.5 M0 79.5 C1.79 66.6, 1.15 51.24, 0 26.5 M0 79.5 C-0.74 62.42, 0.19 43.99, 0 26.5 M0 26.5 C0.03 10.26, 9.24 1.79, 26.5 0 M0 26.5 C1.18 8.43, 8.42 1.25, 26.5 0"
					stroke="#000000"
					stroke-width="1"
					fill="none"
				></path>
			</g>
			<g transform="translate(842.4362850971925 240.39092872570188) rotate(0 180 48)">
				<text
					x="180"
					y="0"
					font-family="Virgil, Segoe UI Emoji"
					font-size="20px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					对原房间结算水电，记录新房间水电表。
				</text>
				<text
					x="180"
					y="24"
					font-family="Virgil, Segoe UI Emoji"
					font-size="20px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					原房间押金与新房间需对齐，多退少补。
				</text>
				<text
					x="180"
					y="48"
					font-family="Virgil, Segoe UI Emoji"
					font-size="20px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					缴纳新房间租金。
				</text>
				<text
					x="180"
					y="72"
					font-family="Virgil, Segoe UI Emoji"
					font-size="20px"
					fill="#000000"
					text-anchor="middle"
					direction="ltr"
					dominant-baseline="text-before-edge"
				>
					本质：先退租再办入住
				</text>
			</g>
			<g stroke-linecap="round">
				<g transform="translate(736.9362850971922 282.8962110018882) rotate(0 39.90474716708536 -0.37062927681728297)">
					<path
						d="M-0.54 0.19 C12.56 0.26, 64.98 0.67, 78.11 0.49 M1.38 -0.75 C14.89 -1.03, 67.13 -1.38, 80.35 -1.25"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(736.9362850971922 282.8962110018882) rotate(0 39.90474716708536 -0.37062927681728297)">
					<path
						d="M52.48 7.52 C60.69 5.14, 65.67 3.68, 81.47 -1.32 M51.56 8.66 C61.45 5.09, 71.23 1.39, 79.97 -1.55"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
				<g transform="translate(736.9362850971922 282.8962110018882) rotate(0 39.90474716708536 -0.37062927681728297)">
					<path
						d="M52.49 -13 C60.71 -10.06, 65.69 -6.21, 81.47 -1.32 M51.57 -11.87 C61.53 -8.47, 71.31 -5.22, 79.97 -1.55"
						stroke="#000000"
						stroke-width="1"
						fill="none"
					></path>
				</g>
			</g>
			<mask></mask>
		</svg>
	);
}
